<template>
    <div id="MainBody">

        <div class="container-fluid" prefix="Header">
            <div class="row " :class="{ 'align-items-center': ($windowWidth>1380) , 'align-items-start': $windowWidth<1380}">
                <div class="col-4">
                    <img class="img-fluid" :style="$windowWidth>1380 ? 'max-height:50px' : 'max-height:40px;margin-top: 10px;'" src="../assets/india/image/logo.png" />
                </div>
                <div class="col-8">
                    <p class="text-right m-0">
                        <a @click="navigateToPageWithTracking(201,scrollToCourse)" class="btn  btn-info m-2 btn_blue_active" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">Curriculum + Pricing </a>
                        <router-link :to="{ name: 'BookDemo',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn btn-info m-2 btn_blue_active" :href="href" @click="navigateToPageWithTracking(202,navigate)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">Book a Free Demo</a>
                        </router-link>
                        <router-link :to="{ name: 'PayNow',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn btn-warning m-2 btn_orange_active" :href="href" @click="navigateToPageWithTracking(203,navigate)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">Buy a Course</a>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
        <div class="row position-relative m-0">
            <div class="container-fluid p-4">
                <img class="position-absolute" style="height:150px;" src="../assets/india/image/bg-material.png" />
                <img class="position-absolute" style="height:80px;right:37%;top:30%" src="../assets/india/image/bg-material.png" />
                <img class="position-absolute" style="right:1.5rem" src="../assets/india/image/bg-design-1.png" />
                <div class="row m-0">
                    <div class="p-md-5 p-sm-3 p-1" :class="{ 'col-7': ($windowWidth>=1000) , 'col-12': $windowWidth<1000}">

                        <h1 style="color:#FF6634">
                            <strong>
                                Online AI, IoT, and Coding<br />
                                Classes Blended With<br />
                                STEM Toys
                            </strong>
                        </h1>
                        <img v-if="$windowWidth<1000" style="width:100%" src="../assets/india/image/hader-img.jpg" />
                        <h2 style="color:#4B4B4D" class="mt-5">
                            <strong>For Grades 1-12</strong>
                        </h2>
                        <p :style="$windowWidth>=1000?'font-size:1.5rem':'font-size:1rem'">
                            Where Kids Build NextGen Projects<br />
                            That Solve Real World Problems
                        </p>
                        <router-link :to="{ name: 'BookDemo',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn  btn-warning btn_orange_active" :href="href" @click="navigateToPageWithTracking(204,navigate)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">Book a free demo</a>
                        </router-link>
                    </div>
                    <div class="col-5 p-5" v-if="$windowWidth>=1000">
                        <img style="width:100%" src="../assets/india/image/hader-img.jpg" />
                    </div>
                </div>
            </div>
            <img class="position-absolute p-0" style="width:100%;bottom:0;z-index:-1" src="../assets/india/image/header-bg.png" />
        </div>
        <div :style="{'backgroundImage': 'url('+require('@/assets/india/image/bg.jpg')+')'}">
            <div class="container-fluid py-5">
                <h3 class="text-center p-lg-4 p-md-3 p-sm-2 p-1" style="color:#404040;font-weight:600">
                    The Journey from Building Online Games to Building Smart-Bikes,<br />
                    Smart-Homes & much more...
                </h3>
                <div class="row align-items-center justify-content-center">
                    <div class="col-2  col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="$refs.carousel.goToPrev()" style="cursor:pointer">
                                <img class="img-fluid" src="../assets/india/image/slider-arrow-left.png" />
                            </a>
                        </p>
                    </div>
                    <div class="col-8 col-sm-10">
                        <agile ref="carousel" :options="myOptions">
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-1.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-2.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-3.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-4.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-5.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-6.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-7.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-9.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-10.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-11.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-12.jpg" />
                            </div>
                            <div class="slide p-3">
                                <img class="img-fluid" src="@/assets/india/image/slider-8.jpg" />
                            </div>
                        </agile>
                    </div>
                    <div class="col-2 col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="$refs.carousel.goToNext()" style="cursor:pointer">
                                <img class="img-fluid" src="../assets/india/image/slider-arrow-right.png" />
                            </a>
                        </p>
                    </div>
                </div>




            </div>
        </div>
        <div class="row position-relative m-0">
            <div class="container-fluid p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;">
                <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>Why</strong> <span style="border-color:#FFCC00">Early Years?</span></h2>
                <div class="row">
                    <div class="col-sm-6 col-md-4 mb-3 mb-sm-2 mb-1">
                        <p class="text-center mb-0"> <img style="max-width:300px;width:100%" src="../assets/india/image/why-early.png" /></p>
                    </div>
                    <div class="col-sm-6 col-md-8 pt-md-5 align-self-center">
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-6">
                                <p class="text-center">
                                    <img style="height:50px;" src="../assets/india/image/why-early-1.png" />
                                </p>
                                <p class="text-center">
                                    A Future Ready Skill Set
                                </p>
                            </div>
                            <div class="col-sm-6 col-md-4 col-6">
                                <p class="text-center">
                                    <img style="height:50px;" src="../assets/india/image/why-early-2.png" />
                                </p>
                                <p class="text-center">
                                    Enhance Cognitive Skills
                                </p>
                            </div>
                            <div class="col-sm-6 col-md-4 col-6">
                                <p class="text-center">
                                    <img style="height:50px;" src="../assets/india/image/why-early-3.png" />
                                </p>
                                <p class="text-center">
                                    2X Improvement in Analytical and Problem Solving Skills
                                </p>
                            </div>
                            <div class="col-sm-6 col-md-4 col-6">
                                <p class="text-center">
                                    <img style="height:50px;" src="../assets/india/image/why-early-4.png" />
                                </p>
                                <p class="text-center">
                                    STEM Skills - Toolkit to All Careers
                                </p>
                            </div>
                            <div class="col-sm-6 col-md-4 col-6">
                                <p class="text-center">
                                    <img style="height:50px;" src="../assets/india/image/why-early-5.png" />
                                </p>
                                <p class="text-center">
                                    Not just Consume, But Create Tech
                                </p>
                            </div>
                            <div class="col-sm-6 col-md-4 col-6">
                                <p class="text-center">
                                    <img style="height:50px;" src="../assets/india/image/why-early-6.png" />
                                </p>
                                <p class="text-center">
                                    Open Doors of Ivy League
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row position-relative m-0" style="background:#33CC99" id="MyCourseDiv">
            <div class="container-fluid p-1 p-sm-2 p-md-3 p-lg-4">
                <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>Our</strong> <span style="border-color:#FF6633">Courses</span></h2>

                <div class="row justify-content-between">
                    <div class="col p-3">
                        <p class="text-center m-0" style="font-style: italic;">Course Mode</p>
                        <p class="text-center">
                            <a class="btn" style="border-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;" @click="setMode(1)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800 , 'btn_orange_active' : (mode==1) , 'btn_orange':mode!=1 }">1:1 Classes</a>
                            <a style="border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;" class="btn" @click="setMode(2)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (mode==2) , 'btn_orange':mode!=2 }">1:15 Classes</a>
                        </p>
                        <p class="text-center m-0" style="font-style: italic;">Select Grade</p>
                        <p class="text-center">
                            <a class="btn m-2" @click="setGrade(1)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==1) , 'btn_orange':grade!=1 }">Grade 1-3</a>
                            <a class="btn m-2" @click="setGrade(2)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==2) , 'btn_orange':grade!=2 }">Grade 4-6</a>
                            <a class="btn m-2" @click="setGrade(3)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==3) , 'btn_orange':grade!=3 }">Grade 7-8</a>
                            <a class="btn m-2" @click="setGrade(4)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800, 'btn_orange_active' : (grade==4) , 'btn_orange':grade!=4 }">Grade 8+</a>
                        </p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12">
                        <swiper :slidesPerView="'1'" :spaceBetween="30" :pagination="{ 'clickable': true}" :breakpoints="{
  '500': {
    'slidesPerView': 1,
    'spaceBetween': 20
  },
  '600': {
    'slidesPerView': 2,
    'spaceBetween': 40
  },
  '1024': {
    'slidesPerView': 3,
    'spaceBetween': 50
  }
}" class="mySwiper">
                            <swiper-slide v-if="mode==1 && grade == 1" style="max-height:695px;display: flex;
    justify-content: space-around;">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/1-3-exp.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price:
                                        </p>
                                        <p style="float: right;
    width: 50%;
    font-size: 130%;" class="m-0 text-center">
                                            INR 2,999
                                        </p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide :class="{'try_half':(mode==2 && $windowWidth>1023)}" v-if="grade == 1" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img v-if="mode==1" class="img-fluid" src="../assets/india/image/curi/1-3-lrnr.png">
                                    <img v-else class="img-fluid" src="../assets/india/image/curi/1-3-lrnr_5.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">6,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>4,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 4,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 2,499</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide v-if="grade == 1" :class="{'try_half':(mode==2 && $windowWidth>1023)}" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/1-3-achvr.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">35,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>25,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 29,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 14,999</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide v-if="mode==1 && grade == 2" style="max-height:695px;display: flex;
    justify-content: space-around;">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/4-6-exp.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price:
                                        </p>
                                        <p style="float: right;
    width: 50%;
    font-size: 130%;" class="m-0 text-center">
                                            INR 2,999
                                        </p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide :class="{'try_half':(mode==2 && $windowWidth>1023)}" v-if="grade == 2" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img v-if="mode==1" class="img-fluid" src="../assets/india/image/curi/4-6-lrnr.png">
                                    <img v-else class="img-fluid" src="../assets/india/image/curi/4-6-lrnr_5.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">6,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>4,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 4,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 2,499</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide v-if="grade == 2" :class="{'try_half':(mode==2 && $windowWidth>1023)}" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/4-6-achvr.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">35,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>25,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 29,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 14,999</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide v-if="mode==1 && grade == 3" style="max-height:695px;display: flex;
    justify-content: space-around;">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/7-8-exp.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price:
                                        </p>
                                        <p style="float: right;
    width: 50%;
    font-size: 130%;" class="m-0 text-center">
                                            INR 2,999
                                        </p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide :class="{'try_half':(mode==2 && $windowWidth>1023)}" v-if="grade == 3" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img v-if="mode==1" class="img-fluid" src="../assets/india/image/curi/7-8-lrnr.png">
                                    <img v-else class="img-fluid" src="../assets/india/image/curi/7-8-lrnr_5.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">6,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>4,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 4,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 2,499</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide v-if="grade == 3" :class="{'try_half':(mode==2 && $windowWidth>1023)}" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/7-8-achvr.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">35,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>25,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 29,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 14,999</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide v-if="mode==1 && grade == 4" style="max-height:695px;display: flex;
    justify-content: space-around;">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/8-exp.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price:
                                        </p>
                                        <p style="float: right;
    width: 50%;
    font-size: 130%;" class="m-0 text-center">
                                            INR 2,999
                                        </p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide :class="{'try_half':(mode==2 && $windowWidth>1023)}" v-if="grade == 4" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img v-if="mode==1" class="img-fluid" src="../assets/india/image/curi/8-lrnr.png">
                                    <img v-else class="img-fluid" src="../assets/india/image/curi/8-lrnr_5.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">6,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>4,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 4,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 2,499</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide v-if="grade == 4" :class="{'try_half':(mode==2 && $windowWidth>1023)}" style="max-height:695px;display: flex;
    justify-content: space-around">
                                <div style="max-width:350px">
                                    <img class="img-fluid" src="../assets/india/image/curi/8-achvr.png">
                                    <div class="px-0 px-sm-1 px-md-2 px-lg-3 py-3 mt-1" style="background: rgb(255, 255, 255);
    display: flex;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 700;">
                                        <p style="float: left;width:50%" class="m-0 text-center">
                                            Price: <span style="color: #B4B4B4;">INR </span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-if="mode==1">35,000</span>
                                            <span style="text-decoration: line-through;color: #B4B4B4;" v-else>25,000</span>
                                        </p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-if="mode==1">INR 29,999</p>
                                        <p style="float: right;width: 50%;font-size: 130%;" class="m-0 text-center" v-else>INR 14,999</p>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>

                    </div>
                </div>
                <div class="row justify-content-between align-items-end">
                    <div class="col-7 p-3 align-self-center " :class="{'d-flex':($windowWidth>950)}">
                        <h3 class="d-inline-block mt-4 mx-3" :style="($windowWidth<950)?'font-size: 1.40rem':'font-size: 1.75rem' ">No cost EMI*</h3>
                        <div class="">
                            <h4 class="text-center d-inline-block p-2 m-2 rounded No_cost_emi" style="width:100px">
                                0 <br />
                                <span>Effective<br />Interest</span>
                            </h4>
                            <h4 class="text-center d-inline-block p-2 m-2 rounded No_cost_emi" style="width:100px">0<br /><span>Processing<br />Fee</span></h4>
                        </div>
                    </div>
                    <div class="col-5 p-3">

                        <p class="text-right">
                            <router-link :to="{ name: 'BookDemo',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                                <a class="btn btn_orange_active  m-2" :href="href" @click="navigateToPageWithTracking(205,navigate)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">Book a Free Demo</a>
                            </router-link>
                            <a @click="navigateToPageWithTracking(206,downloadPdf)" class="btn btn_orange   m-2" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">Download Curriculum</a>
                        </p>
                    </div>
                </div>
                <p class="text-center">The course fee is refundable. No questions asked.</p>
            </div>
        </div>
        <div class="row position-relative m-0">
            <div class="container-fluid p-1 p-sm-2 p-md-3 p-lg-4" style="padding-top:0px!important;padding-bottom:0px !important">
                <img class="position-absolute" style="height:150px;top:100px" src="../assets/india/image/bg-design-2.png" />
                <img class="position-absolute" style="right:1.5rem" src="../assets/india/image/bg-4.png" />
                <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4"><strong>With Us,</strong> <span style="border-color:#FFCC99">It's Bigger & Better</span></h2>
                <div class="row m-0 justify-content-between">
                    <div class="col-md-5 col-lg-3 col-sm-4 col-4">
                        <p class="m-0"><img src="../assets/india/image/with-us-1.png" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                    <div class="col-md-7 col-sm-8 col-8" v-if="$windowWidth<990">
                        <p class="m-0"><img src="../assets/india/image/with-us-2.png" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                    <div class="col-md-12 col-lg-4 col-sm-12 ">
                        <p class="text-center p-2 m-3" style="background:#FFCC99;border-radius: 20px 20px 0px 0px">
                            Not just live tutoring, it’s revision and assessment modules
                        </p>
                        <p class="text-center p-2 m-3" style="background:#33CC99">
                            Not just coding & apps, build robots, smart gadgets and more

                        </p>
                        <p class="text-center p-2 m-3" style="background:#FFCC00">
                            Not just online, but use educational kits for hands on experience

                        </p>
                        <p class="text-center p-2 m-3" style="background:#FF9999;border-radius:0px 0px 20px 20px ">
                            Not just learning, it’s playtime and fun

                        </p>
                    </div>
                    <div class="col-md-4" v-if="$windowWidth>990">
                        <p class="m-0"><img src="../assets/india/image/with-us-2.png" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid p-5" style="background:#FF6633">
            <div class="row justify-content-center">
                <div class="col-md-2 col-sm-4 col-6">
                    <p class="text-center text-light">
                        <img class="img-fluid" src="../assets/india/image/5ic-byond.png" /><br />
                        Guaranteed Improvements
                    </p>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <p class="text-center text-light">
                        <img class="img-fluid" src="../assets/india/image/5ic-self.png" /><br />
                        Easy Class Scheduling
                    </p>
                </div>
                <div class="col-md-2 col-sm-4 col-12">
                    <p class="text-center text-light">
                        <img class="img-fluid" src="../assets/india/image/5ic-curi.png" /><br />
                        Simplified Curriculum
                    </p>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <p class="text-center text-light">
                        <img class="img-fluid" src="../assets/india/image/5ic-plat.png" /><br />
                        Interactive Platform
                    </p>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <p class="text-center text-light">
                        <img class="img-fluid" src="../assets/india/image/5ic-iit.png" /><br />
                        IIT Alumni Backed
                    </p>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-5 pr-5">
            <div class="row justify-content-center position-relative">
                <img class="img-fluid position-absolute" style="left:0px;" src="../assets/india/image/bg-5.png" />
                <div class="col">
                    <h2 class="text-center">
                        <strong>Hobbies All Along,</strong> <span style="border-color:#FFCC99">Skills For Long</span>
                    </h2>
                    <h4 class="text-center p-2" style="font-weight:600">
                        Journey of An Innovator
                    </h4>
                    <p class="text-center p-1 p-sm-2 p-md-3 p-lg-4">
                        <img v-if="$windowWidth>900" class="img-fluid" style="max-width:80%" src="../assets/india/image/skill-all-along.jpg" />
                        <img v-else class="img-fluid" style="max-width:100%" src="../assets/india/image/skill-all-along-mobile.jpg" />
                    </p>

                </div>
            </div>
            <div class="row justify-content-center position-relative">
                <img class="img-fluid position-absolute" style="right:0px;bottom:0px" src="../assets/india/image/bg-5.png" />
                <div class="col-12 col-md-10">
                    <h4 class="text-center pb-5">
                        Become a part of Tinkerly's Innovator Club.
                    </h4>
                </div>
            </div>
        </div>
        <div class="row position-relative m-0" style="background:#69D4C4">
            <div class="container p-1 p-sm-2 p-md-3 p-lg-4" style="padding-top:0px!important;padding-bottom:0px !important">
                <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4"><strong>Watch</strong> <span style="border-color:#FFCC00">In Action</span></h2>
                <h4 class="text-center p-2" style="font-weight:600">
                    Be A Stress-free Parent & Limit Your Child's Unproductive Screen Time!
                </h4>
                <div class="row m-0 justify-content-center align-items-center">
                    <div class="col-md-3 col-12" v-if="$windowWidth>800">
                        <p class="m-0"><img src="../assets/india/image/watch.png" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                    <div class="col-2 col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="$refs.carousel_video.goToPrev()" style="cursor:pointer">
                                <img class="img-fluid" src="../assets/india/image/slider-arrow-left.png" />
                            </a>
                        </p>
                    </div>
                    <div class="col-md-6 col-8">
                        <agile ref="carousel_video" :options="VideoOptions">
                            <div class="slide">

                                <p class="text-center">
                                    <iframe width="620" style="max-width:100%" height="380"
                                            src="https://www.youtube.com/embed/BBkzgtcD6Qo">
                                    </iframe>
                                </p>
                                <h5 class="text-center p-md-2 p-0" style="font-weight:600">
                                    Discover the Revolutionary Way Of Hands-On Coding With Tinkerly!

                                </h5>
                            </div>
                            <div class="slide">

                                <p class="text-center">
                                    <iframe width="620" style="max-width:100%" height="380"
                                            src="https://www.youtube.com/embed/GHT9KvU4WJo">
                                    </iframe>
                                </p>
                                <h5 class="text-center p-md-2 p-0" style="font-weight:600">
                                    Limit Your Child's Unproductive Screen Time With Tinkerly!
                                </h5>
                            </div>
                            <div class="slide">

                                <p class="text-center">
                                    <iframe width="620" style="max-width:100%" height="380"
                                            src="https://www.youtube.com/embed/jj10sRiAa38">
                                    </iframe>
                                </p>
                                <h5 class="text-center p-md-2 p-0" style="font-weight:600">
                                    Take Your Child's Learning Journey Beyond The Screens!

                                </h5>
                            </div>

                        </agile>
                    </div>
                    <div class="col-2 col-sm-1 text-center">
                        <p class="text-center">
                            <a @click="$refs.carousel_video.goToNext()" style="cursor:pointer">
                                <img class="img-fluid" src="../assets/india/image/slider-arrow-right.png" />
                            </a>
                        </p>
                    </div>
                    <div class="col-md-3 col-12" v-if="$windowWidth<800">
                        <p class="m-0"><img src="../assets/india/image/watch.png" style="max-height:400px" class="img-fluid" /></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid py-5" style="background:#FFCC00">
            <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>Expert</strong> <span style="border-color:#FF9933">Educators</span></h2>
            <h3 class="text-center">Ensuring Learning is Play & Play is Productive</h3>

            <div class="row align-items-center">
                <div class="col-2 col-sm-1 text-center">
                    <p class="text-center">
                        <a @click="$refs.carousel_inst.goToPrev()" style="cursor:pointer">
                            <img class="img-fluid" src="../assets/india/image/slider-arrow-left.png" />
                        </a>
                    </p>
                </div>
                <div class="col-8 col-sm-10">
                    <agile ref="carousel_inst" :options="myOptions">
                        <div class="slide px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                            <div style="background:#fff;min-height: 500px;" class="px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                                <h5 class="text-center">Primary Years Program</h5>
                                <h5 class="text-center" style="color:#A6A6A6">Grade 1-6</h5>
                                <p class="text-center"><img class="img-fluid" style="width: 60%;max-width:190px" src="@/assets/india/image/pranjal.png" /></p>
                                <h4 class="text-center" style="color:#FF6634">Pranjal Sharma</h4>
                                <p style="color:#A6A6A6" class="text-center">
                                    Master in Mechatronics with 4+ years  of experience in Education and Online Classes
                                </p>
                            </div>
                        </div>
                        <div class="slide px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                            <div style="background:#fff;min-height: 500px;" class="px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                                <h5 class="text-center">Middle Years Program</h5>
                                <h5 class="text-center" style="color:#A6A6A6">Grade 7-8</h5>
                                <p class="text-center"><img class="img-fluid" style="width: 60%;max-width:190px" src="@/assets/india/image/deven.png" /></p>
                                <h4 class="text-center" style="color:#FF6634">Devendra Rathore</h4>
                                <p style="color:#A6A6A6" class="text-center">
                                    Engineer turned educator with 3+ years of experience who has provided 50+ workshops and trained 3000+ students and teachers.
                                </p>
                            </div>
                        </div>
                        <div class="slide px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                            <div style="background:#fff;min-height: 500px;" class="px-0 px-sm-1 px-md-2 px-lg-3 py-3">
                                <h5 class="text-center">Upper Years Program</h5>
                                <p class="text-center" style="color:#A6A6A6">Grade 8+</p>
                                <p class="text-center"><img class="img-fluid" style="width: 60%;max-width:190px" src="@/assets/india/image/kapil.png" /></p>
                                <h4 class="text-center" style="color:#FF6634">Kapil Arya</h4>
                                <p style="color:#A6A6A6" class="text-center">
                                    An IIT Delhi Graduate, with 6+ years of experience in education research, curriculum and training. Mentored students of 100+ schools for Innovation.
                                </p>
                            </div>
                        </div>

                    </agile>
                </div>
                <div class="col-2 col-sm-1 text-center">
                    <p class="text-center">
                        <a @click="$refs.carousel_inst.goToNext()" style="cursor:pointer">
                            <img class="img-fluid" src="../assets/india/image/slider-arrow-right.png" />
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container-fluid position-relative m-0 p-0" style="overflow: hidden;">
            <img v-if="$windowWidth>800" src="../assets/india/image/bg-10.png" class="position-absolute " style="bottom:0px;left:150px;max-height:450px;" />
            <div class="container p-1 p-sm-2 p-md-3 p-lg-4" style="padding-top:0px!important;padding-bottom:0px !important">
                <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4">
                    <strong>See What Little Minds</strong> <span style="border:none;display:inline">
                        are Capable of Tinkerly Unleashes it!
                    </span>
                </h2>

            </div>
            <div class="row m-0 align-items-center justify-content-end">

                <div class="col-sm-2 col-1 p-1 col-md-1 col-lg-1">
                    <p class="text-center">
                        <a @click="Mysyper.slidePrev()" style="cursor:pointer">
                            <img class="img-fluid" src="../assets/india/image/arrow-left-gray.png" />
                        </a>
                    </p>
                </div>
                <div class="col-lg-6 col-sm-7 col-10 col-md-6 ">
                    <swiper @swiper="onSwiper" :slidesPerView="'auto'" :spaceBetween="10" class="mySwiper">
                        <swiper-slide style="max-width:250px">
                            <div class="mobile m-2 p-3">
                                <p style="margin:0px !important">
                                    <span class="s_red"></span>
                                    <span class="s_green"></span>
                                    <span class="s_yello"></span>
                                </p>
                                <p class="text-center"><img class="img-fluid" src="../assets/india/image/what-little-1.jpg" style="max-height: 350px;"></p>
                                <h4 class="text-center w-100 mt-2">Shantanu</h4>
                                <p class="text-center w-100 m-0" style="color:#A8A8A7;">Robo - Pet</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide style="max-width:250px">
                            <div class="mobile m-2 p-3">
                                <p style="margin:0px !important">
                                    <span class="s_red"></span>
                                    <span class="s_green"></span>
                                    <span class="s_yello"></span>
                                </p>
                                <p class="text-center"><img class="img-fluid" src="../assets/india/image/what-little-2.jpg" style="max-height: 350px;"></p>
                                <h4 class="text-center w-100 mt-2">Naseera</h4>
                                <p class="text-center w-100 m-0" style="color:#A8A8A7;">Smart Hand Wash</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide style="max-width:250px">
                            <div class="mobile m-2 p-3">
                                <p style="margin:0px !important">
                                    <span class="s_red"></span>
                                    <span class="s_green"></span>
                                    <span class="s_yello"></span>
                                </p>
                                <p class="text-center"><img class="img-fluid" src="../assets/india/image/what-little-3.jpg" style="max-height: 350px;"></p>
                                <h4 class="text-center w-100 mt-2">Ishaan </h4>
                                <p class="text-center w-100 m-0" style="color:#A8A8A7;">Anti - theft phone</p>
                            </div>
                        </swiper-slide>
                    </swiper>

                </div>
                <div class="col-sm-2 col-1 p-1 col-md-1 col-lg-1">
                    <p class="text-center">
                        <a @click="Mysyper.slideNext()" style="cursor:pointer">
                            <img class="img-fluid" src="../assets/india/image/arrow-right-gray.png" />
                        </a>
                    </p>
                </div>
            </div>
            <div style="height:300px" v-if="$windowWidth<800"></div>
            <img src="../assets/india/image/see-what.png" class="position-absolute" style="bottom:120px;left:0px;width:40%;z-index: 1;
    max-width: 300px;min-width:230px;" />
            <div style="background:#FF9933;padding: 50px 100px;padding-right: 0px !important;">
                <h3 style="margin-left: 20%;margin-top: 30px">Your child can also make this... </h3>
            </div>
        </div>

        <div class="container-fluid py-5" style="background:#DCF7FE">
            <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>What</strong> <span style="border-color:#2BB7D0">Parents Say?</span></h2>
            <div class="container">
                <div class="row ">
                    <div class="col-12 pr-5">
                        <p class="text-right pr-5">
                            <img style="cursor:pointer;height:40px" @click="$refs.carousel_parent.goToPrev()" class="m-2" src="../assets/india/image/aero-selected-left.png" />
                            <img style="cursor:pointer;height:40px" @click="$refs.carousel_parent.goToNext()" class="m-2" src="../assets/india/image/aero-selected-right.png" />
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <agile ref="carousel_parent" :options="myOptions_2">
                            <div class="slide p-3">
                                <div style="background:#fff;min-height:300px;border-left:10px solid #FFF;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/india/image/p-summan-jangir-mother.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mrs. Suman Jangir </h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent</h5>
                                            </td>
                                        </tr>
                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        I got my son enrolled in Tinkerly’s online coding classes & I must say I was completely taken by surprise when I saw my son so excited and indulged in learning. I can see him progress every day through the wonderful projects that he has created. A big thumbs up to the Tinkerly team!!
                                    </p>
                                </div>
                            </div>
                            <div class="slide p-3">
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/india/image/p-shravani-mother.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mrs. Shravani</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent</h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        When my daughter told me that she is interested in learning coding, AI & robotics, I thought it’s just like her one of those interests that she’ll be doing just for a week. But it turned out to be the opposite of what I thought. She got so happy and excited and loved to tinker and build something interesting with the kit every day. Thank you, Tinkerly, for proving me wrong.

                                    </p>
                                </div>
                            </div>
                            <div class="slide p-3">
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/india/image/p-pramod-sharma.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mr. Pramod Sharma</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent</h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        I have never seen my son so excited and interested for any class except for the Tinkerly’s coding classes. Tinkerly has really made the learning fun and engaging through their online classes.
                                    </p>
                                </div>
                            </div>
                            <div class="slide p-3">
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/india/image/p-prerana-chaudhary-mother.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mrs. Prerna Chaudhary</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent</h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        Tinkerly is unlike any other usual coding classes. My son attended the classes, and it was a great experience. It indeed has strengthened the basics of coding in my son. Now he builds so many different and techy stuff each day. It was beyond my imagination. Kudos to the teachers!!

                                    </p>
                                </div>
                            </div>
                            <div class="slide p-3">
                                <div style="background:#fff;min-height:300px;border-top-right-radius:25px" class="p-3">
                                    <table>
                                        <tr>
                                            <td style="width:100px">
                                                <img class="img-fluid rounded-circle" style="height:80px;" src="@/assets/india/image/p-pankaj-jangir-mother.jpg" />
                                            </td>
                                            <td>
                                                <h4 class="text-left mb-0" style="color:#FF6634">Mrs. Pankaj</h4>
                                                <h5 class="text-left" style="color:#A6A6A6">Parent</h5>
                                            </td>
                                        </tr>

                                    </table>
                                    <p class="text-left pl-5 pt-3">
                                        Tinkerly's coding course has provided a great and fantastic experience for my child. I have never seen my daughter taking this much interest in coding. The course was amazing, and the teachers were so knowledgeable and polite. My daughter has gained good knowledge and insight into coding.

                                    </p>
                                </div>
                            </div>
                        </agile>
                    </div>
                </div>
            </div>

        </div>
        <div class="container-fluid p-lg-5 p-md-3 p-1 py-3" style="background:#BBF0FE">
            <h4 style="font-weight:700" class="text-center">
                Appreciated by
            </h4>
            <div class="row justify-content-center mt-5">
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="../assets/india/image/trust-logo-fob.png" />
                </div>
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="../assets/india/image/trust-logo-iee.png" />
                </div>
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="../assets/india/image/trust-logo-nascom.png" />
                </div>
                <div class="col-lg-2  col-3">
                    <img class="img-fluid" src="../assets/india/image/trust-logo-stem.png" />
                </div>
            </div>
        </div>
        <div class="container-fluid p-lg-5 p-md-3 p-1">

            <div class="row justify-content-around">
                <div class="col-lg-2 col-4">
                    <h2 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">150,000+</h2>
                    <p class="text-center mt-0">Students</p>
                </div>
                <div class="col-lg-2 col-4">
                    <h2 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">7000+</h2>
                    <p class="text-center mt-0">Teachers</p>
                </div>
                <div class="col-lg-2 col-4">
                    <h2 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">300,000+</h2>
                    <p class="text-center mt-0">learning hours</p>
                </div>

            </div>
        </div>
        <div class="container-fluid pt-5" style="background:#EFEFF7">
            <h2 class="text-center p-1 p-sm-2 p-md-3 p-lg-4" style="padding-bottom:0px !important;"><strong>How</strong> <span style="border-color:#FF9999">it Works?</span></h2>
            <div class="row pt-5 m-0 justify-content-end">
                <div class="col-6 col-md-6 col-lg-3">
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633;">Step</span> 1</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        Book a first free demo class with us. You can book your free demo class from our official website code.tinker.ly.

                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 2</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        You need to Sign Up & Enter your details in the field.

                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 3</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        You can Book a Demo class slot at your convenience. You will have the flexibility to schedule your time & date.

                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 4</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        Our educators will take the demo session of your child for a duration of 40 minutes to an hour.
                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 5</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        Our team representative will contact you regarding the demo feedback and for sharing more info about the course

                    </h5>
                </div>
                <div class="col-6 col-md-6 col-lg-3">


                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 6</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        If you like the demo session, then you can continue by buying any of the available course packages.


                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 7</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        You can schedule your weekly sessions (recommended 2-3 sessions per week) and your child can continue the learning journey with us.


                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 8</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        After Choosing any of our course packages you will get access to the recorded lessons available on our Lets Tinker App


                    </h5>
                    <h3 style="font-size: calc(0.9rem + 0.8vw);"><span style="color:#FF6633">Step</span> 9</h3>
                    <h5 class="mb-4" style="font-size: calc(0.9rem + 0.3vw);">
                        You can start your learning journey and enjoy the flexibility of learning anytime and anywhere.
                    </h5>
                </div>
                <div class="col-sm-9 col-md-6 col-lg-4 position-relative" style="min-height:300px;z-index:1">
                    <img class="img-fluid position-absolute" style="min-height:300px;bottom:0;right:0" src="../assets/india/image/how-it.png" />
                </div>
            </div>

        </div>
        <div class="container-fluid py-5 position-relative" style="background-position: left bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: rgb(105, 212, 196);"
             :style="{'backgroundImage': 'url('+require('@/assets/india/image/faq.png')+')'}">

            <h2 class="text-center p-5" style="padding-bottom:0px !important;"> <span style="border-color:#FF9999"><strong>FAQ</strong>s</span></h2>
            <div class="row py-5 m-0 justify-content-end" style="min-height:500px;">
                <div class="col-12 col-md-8 col-lg-7">
                    <vue-collapsible-panel-group accordion>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                On which Platform will the course be conducted?

                            </template>
                            <template #content>
                                There are two types of sessions:
                                <br />
                                Recorded Session will be delivered on Let’s Tinker App along with Quiz and Assignments.
                                Live classes will be delivered on Google Meet/Zoom.

                            </template>
                        </vue-collapsible-panel>

                        <vue-collapsible-panel :expanded="false">
                            <template #title>

                                Is there a Refund Policy? What are the terms?

                            </template>
                            <template #content>
                                You can cancel the subscription anytime within the first 2 Weeks or downgrade to other packages. We have No Questions Asked Refund Policy. There are zero cancellation charges and if in case you request a refund post 2 Weeks then only the price for availed sessions and kit will be recovered.
                            </template>
                        </vue-collapsible-panel>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                Is there an EMI option available?
                            </template>
                            <template #content>
                                Yes, no cost EMI option is available via 3rd party partners.
                            </template>
                        </vue-collapsible-panel>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                In which language courses are available?

                            </template>
                            <template #content>
                                Currently in English and Hindi.

                            </template>
                        </vue-collapsible-panel>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                Are there any charges for Demo Class?

                            </template>
                            <template #content>
                                The Demo class will be scheduled free of cost for the child and will include a Certificate of Participation as well which can be downloaded freely via the app


                            </template>
                        </vue-collapsible-panel>
                        <vue-collapsible-panel :expanded="false">
                            <template #title>
                                How is Quality of Teachers Ensured?


                            </template>
                            <template #content>
                                Our courses are curated by a team of IITians. They are backed by extensive experience of training teachers and students in over  500+ schools. Live classes will be delivered by graduates of Top tier institutions who have an experience of mentoring and tutoring. All our faculties go through rigorous training to ensure best learning outcomes for all children.

                            </template>
                        </vue-collapsible-panel>
                    </vue-collapsible-panel-group>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-9 col-md-7">
                    <h4 class="text-center font-weight-bolder">
                        Are you interested in<br />
                        exploring your kid's love for Coding?
                    </h4>
                    <p class="text-center ">
                        <router-link :to="{ name: 'BookDemo',params: { username: $route.params.username }}" v-slot="{ href, navigate}" custom>
                            <a class="btn btn-warning m-2 btn_orange_active" :href="href" @click="navigateToPageWithTracking(207,navigate)" :class="{ 'btn-lg': ($windowWidth>1380) , 'btn-sm': $windowWidth<800}">Book A Free Demo</a>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
        <footer class="pt-4 pl-4 pb-4" style="background:#EFEFEF">

            <div class="row m-0">
                <div class="col-md-4 p-5">
                    <!-- //logo image -->
                    <img src="../assets/india/image/logo.png" style="max-width:250px;" class="img-fluid" />

                    <p style="font-size:1.2em" class="mt-4">
                        An initiative by IIT-D & XLRI Alumni to promote Coding Skills & STEM education among young minds. It consists of superior pedagogy, blended learning, and a play-based curriculum to enable interactive learning for the students of grades 1-12.
                    </p>


                </div>
                <div class="col-md-8"> 
                    <div class="footer-menu">
                        <span class="menu-top-border"></span>
                        <div class="menu-footer-menu-container">
                            <ul class="menu-list">
                                <li class="menu-item "><a title="About Us" target="_blank" href="https://tinker.ly/about-us/" @click="navigateToPageWithTracking(208,null)" class="nav-link" itemprop="url"><span itemprop="name">About Us</span></a></li>
                                <li class="menu-item "><a title="Contact us" target="_blank" href="https://tinker.ly/contact-us/" @click="navigateToPageWithTracking(209,null)" class="nav-link" itemprop="url"><span itemprop="name">Contact us</span></a></li>
                                <li class="menu-item "><a title="Lets Tinker" target="_blank" href="https://letstinker.tinker.ly/" class="nav-link" itemprop="url"><span itemprop="name">Lets Tinker</span></a></li>
                                <li class="menu-item "><a title="Partner with us" target="_blank" href="https://tinker.ly/partner/" class="nav-link" itemprop="url"><span itemprop="name">Partner with us</span></a></li>
                                <li class="menu-item "><a title="Blog" target="_blank" href="https://tinker.ly/blog/" class="nav-link" @click="navigateToPageWithTracking(210,null)" itemprop="url"><span itemprop="name">Blog</span></a></li>
                                <li class="menu-item "><a title="Become a Teacher" target="_blank" href="https://tinker.ly/join-tinkerlys-teacher-program-to-enlighten-young-minds-about-stem-coding/" class="nav-link" itemprop="url"><span itemprop="name">Become a Teacher</span></a></li>

                            </ul>
                        </div>
                        <span class="menu-bottom-border"></span>

                    </div>
                    <div class="row justify-content-end">
                        <div class="col-md-10">
                            <h4 class="text-mohave font-weight-bold">SOCIAL MEDIA</h4>
                            <p style="color:black;font-size:1.2em">Follow us to stay connected and receive instant updates.</p>
                            <ul class="menu-list p-0 m-0 align-self-end">

                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://www.instagram.com/tinkerlygroup/" target="_blank"><img style="height:35px" src="../assets/svg/instagram-brands.svg" /></a>
                                </li>



                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://www.facebook.com/tinkerlygroup/" target="_blank"><img style="height:35px" src="../assets/svg/facebook-square-brands.svg" /></a>
                                </li>



                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://www.youtube.com/channel/UCJccc_N6nxOtOd_2r2OCRRg" target="_blank"><img style="height:35px" src="../assets/svg/youtube-brands.svg" /></a>
                                </li>



                                <li class="menu-item " style="font-size:2em;font-weight:900;margin-right: 25px">
                                    <a href="https://www.linkedin.com/company/tinkerlygroup/" target="_blank"><img style="height:35px" src="../assets/svg/linkedin-brands.svg" /></a>
                                </li>





                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-md-12 base-footer-left">
                        <span class="base-footer-left-top-border"></span>
                        <p style="font-size:1.2em">Copyright © Elation Edtech Pvt Ltd 2021. All Rights Reserved</p>
                    </div>
                    <div class="col-lg-6 col-md-12 col-md-12 base-footer-right-links">
                        <ul class="menu-list">
                            <li class="menu-item"><a style=" border-right: 1px solid #000;margin-right:25px;padding-right:25px" href="https://tinker.ly/privacy-policy-for-tinkerly/" target="_blank"> Privacy Policy</a></li>
                            <li class="menu-item"><a style=" margin-right:25px" href="https://tinker.ly/terms-and-conditions/" target="_blank">Terms & Conditions</a></li>
                            <li class="menu-item"><a style="border-left: 1px solid #000;padding-left:25px" href="https://tinker.ly/guidelines-for-setting-up-of-atal-tinkering-laboratories/" target="_blank">Guidelines for ATL</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>
<style scoped>
@media screen and (min-width: 1200px) {
  h2,h1{
      font-size: 2.5rem;
  }
}
@media screen and (max-width: 1200px) {
  h2,h1{
      font-size: calc(1.375rem + 1.5vw);
  }
}
@media screen and (max-width: 1000px) {
  h1{
      font-size: calc(1.325rem + 0.9vw);
  }
}
.try_half{
    width: 50% !important;
}

.menu-list{list-style-type:none}
.menu-item{list-style-type:none;display:inline-block;color:#000}
.menu-item a{font-size:1.2em;color:#000}
.menu-bottom-border{border-bottom:1px solid #000;display:inline-block;width:95%;position:relative;right:0;bottom: 16px;}
.menu-top-border{border-bottom:1px solid #000;display:inline-block;width:97%;position:relative;left:-94px;bottom:16px}
.base-footer-left-top-border{border-bottom:1px solid #000;display:block;width:20%;position:relative;left:0;bottom:12px}
.grade_display{
        top: 12%;
    left: 10%;
    z-index: 1;
    color: #fff;
    font-weight: 600;
    font-size: 120%;
    position: absolute;
}
.count_display{
        top: 10%;
    
    color: black;
    font-size: 160%;
    z-index: 1;
    font-weight: 700;
    position: absolute;
}
.Curriculum_display{
    position: absolute;
    
    left: 22%;
    width: 74%;
    font-size: 95%;
}
.Curriculum_display span{
    font-size: 120%;
    font-weight: 600;
    color: #FF6634;
}

.price_display{
    position: absolute;
    top: 88.5%;
    left: 32%;
    font-weight: 600;
}
.price_cut_display{
    position: absolute;
        top: 88.5%;
    left: 65%;
    font-weight: 600;
    color: #999999;
    text-decoration: line-through;
}
.row, .container-fluid ,.container {
    overflow-x: hidden;
}
    .position-relative .position-absolute{
        width:auto;
    }
    .position-absolute {
        z-index: -1;
    }
    .text-right , .text-md-right{
        text-align:right;
    }
   
    * {
        font-family: 'Mont' !important;
    }
    h1,h2{
        font-weight:600;
    }
        h2 span {
            border-bottom: 4px solid;
            display: inline-block;
            line-height: 35px;
           
        }
    .btn_blue_active {
        background: #2BB7D0;
        color: #fff;
        border: 2px #2BB7D0 solid;
    }
        .btn_blue_active:hover {
            background: #26A2B8
        }
    .btn_orange_active {
        background: #FF6633;
        color:#fff;
        border:2px #FF6633 solid;
    }
        .btn_orange_active:hover {
            background: #E65C2E;
            color: #fff;
            border: 2px #FF6633 solid;
        }
        .btn_orange {
            background: #fff;
            color: #FF6633;
            border: 2px #FF6633 solid;
        }
    .btn_orange:hover {
        background: #FF6633;
        color: #fff;
    }
    .No_cost_emi {
        background: #fff;
        line-height: 18px;
        border-radius: 12px !important;
    }
        .No_cost_emi span {
            font-size: 1rem;
            line-height: 10px;
            color: #B3B3B3;
        }
    .mobile {
        border: 1px solid white;
        box-shadow: 0px 0px 3px 3px #e9e8e8;
        border-radius: 10px;
        background: white;
        padding-top: 0px !important;
    }
        .mobile p span {
            width: 10px !important;
            display: inline-block;
        }
    .s_red:before {
        content: '\25CF';
        color: #FF6634;
        display: inline-block;
        width: 6px;
    }
    .s_green:before {
        content: '\25CF';
        color: #65C3A4;
        display: inline-block;
        width: 6px;
    }
    .s_yello:before {
        content: '\25CF';
        color: #F9A137;
        display: inline-block;
        width: 6px;
    }
</style>
<script>
    import '../assets/css/bootstrap.css'
    import { VueAgile } from 'vue-agile'
    import 'vue-agile/dist/VueAgile.css'
    import '../assets/Font/stylesheet.css'
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper-bundle.min.css';
    import SwiperCore, {
        Pagination, Navigation
    } from 'swiper/core';
    SwiperCore.use([Pagination, Navigation]);
    import { VueCollapsiblePanel, VueCollapsiblePanelGroup} from '@dafcoe/vue-collapsible-panel'
    import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
    import {trackerLeadSquaredEvent,trackerFBPixel} from '@/assets/js/TinkerlyTracking.js';
export default {
  name: 'Home',
        components: {
            agile: VueAgile,
            Swiper,
            SwiperSlide,
            VueCollapsiblePanelGroup,
            VueCollapsiblePanel
        },
        data() {
            
            return {
                Mysyper:"",
                mode:1,
                grade:1,
                pdfName:{
                    m1g1:"class 1-3 for 1 to 1.pdf",
                    m1g2:"class 4-6 for 1 to 1.pdf",
                    m1g3:"class 7-8 for 1 to 1.pdf",
                    m1g4:"class 8 for 1 to 1.pdf",
                    m2g1:"class 1-3 for 1 to 5.pdf",
                    m2g2:"class 4-6 for 1 to 5.pdf",
                    m2g3:"class 7-8 for 1 to 5.pdf",
                    m2g4:"class 8 for 1 to 5.pdf",
                },
                myOptions: {
                    navButtons: false,
                    dots: false,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 700,
                            settings: {
                                slidesToShow: 2,
                                
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,

                            }
                        }
                        ,
                        {
                            breakpoint: 400,
                            settings: {
                                slidesToShow: 1,

                            }
                        }
                    ]

                   
                },
                myOptions_2: {
                    navButtons: false,
                    dots: false,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 2,

                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,

                            }
                        }
                        ,
                        {
                            breakpoint: 400,
                            settings: {
                                slidesToShow: 1,

                            }
                        }
                    ]

                },
                VideoOptions: {
                     navButtons: false,
                    dots: false,
                    slidesToShow: 1
            }
            };
        },
        methods: {
            navigateToPageWithTracking(id,func){
                trackerLeadSquaredEvent(id);
                if(func!=null)
                    func();
            },
            onSwiper(swiper) {
                this.Mysyper = swiper;
            },
            
            downloadPdf(){
                trackerFBPixel('Download');
     this.$gtag.event("conversion", {
              send_to: "AW-810557807/I6CpCJXGgYYDEO_CwIID",
            });
                var key = "m"+this.mode+"g"+this.grade;
                //console.log(this.pdfName[key]);
                window.open("/pdf/"+this.pdfName[key], '_blank').focus();
               
            },
            setGrade(g){
                this.grade = g;
            },
            setMode(m){
                this.mode = m;
            },
            
            scrollToCourse(){
                const chatLogDiv = document.getElementById('MyCourseDiv')
                var top = chatLogDiv.offsetTop;
                window.scrollTo(0, top);
                trackerFBPixel('Price');
                 this.$gtag.event("conversion", {
              send_to: "AW-810557807/qRCnCM2mz4UDEO_CwIID",
            });
            }

        },
        mounted: function () {
            window.__lc = window.__lc || {};
            window.__lc.license = 13188978;
            (function (n, t, c) {
                function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) }
                var e = {
                    _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () {
                        i(["once", c.call(arguments)])
                    }, off: function () { i(["off", c.call(arguments)]) },
                    get: function () {
                        if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                        return i(["get", c.call(arguments)])
                    }, call: function () { i(["call", c.call(arguments)]) }, init: function () {
                        var n = t.createElement("script");
                        n.async = !0, n.type = "text/javascript",
                            n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
                    }
                };
                !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
            }(window, document, [].slice))
        }
}
</script>


